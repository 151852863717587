<template>
  <div
    class="mats-listing__wrapper color-listing__wrapper"
    style="margin-top: -8px;"
  >
    <button
      v-b-modal.modal-add-color
      class="button"
      :class="{'button--hidden': createOrder}"
    >
      <b-icon-plus />
      {{ $t('AddColor') }}
    </button>
    <!-- <div class="table__search-input">
      <b-icon-search />
      <input
        v-model="searchTerm"
        type="text"
        style="width: 255px"
      >
    </div> -->
    <!-- <hr class="mt-2 mb-3"> -->

    <div style="width: 100%;">
      <table
        class="team_table"
        :class="{'button--disabled': getCreatedOrdersByOrderNumber.length == 0}"

        style="width: 100%; box-shadow: none; font-size: 12px"
      >
        <thead style="border-bottom: 1px solid #dcdfe6; color: #606266; text-align: left">
          <tr>
            <th style="padding: 10px; border-radius: 0px 0px 0px 0px; color:#262E6C !important; font-size: 15px;">
              {{ $t('ArticleName') }}
            </th>
            <!-- <th
              v-if="client != 'DrenushaXharra'"
              style="padding: 10px; border-radius: 0px 0px 0px 0px;"
            >
              {{ $t("Stock") }}
            </th>
            <th
              v-if="client != 'DrenushaXharra'"
              style="padding: 10px; border-radius: 0px 0px 0px 0px;"
            >
              {{ $t("Sell") }}
            </th> -->
            <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left">
              {{ $t('Assign') }}
            </th>
            <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: left">
              {{ $t("Edit") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in getCreatedOrdersByOrderNumber"
            :key="index"
            style="border-bottom: 1px solid #dcdfe6; color: #606266; text-align: left;"
            :style="classNameCh === item.orderNumber ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
            @click="matProps(item)"
          >
            <td style="padding: 9px;font-weight: 400; color: black;">
              {{ item.articleNumber }}
            </td>
            <!-- <td style="padding: 9px">
              {{ item.inStock }}
            </td>
            <td style="padding: 9px">
              <b-button
                v-b-modal.sell-article-modal
                size="sm"
                :disabled="item.inStock == 0"
                @click=" articleNAME(item.articleNumber); orderItemID(item.orderItemId);sellArticleTeamName()"
              >
                {{ item.sold }}
              </b-button>
            </td> -->
            <!-- <td
              v-if="client != 'DrenushaXharra'"
              style="padding: 9px; text-align: center;"
            >
              {{ item.inStock }}
            </td>
            <td
              v-if="client != 'DrenushaXharra'"
              style="padding: 9px"
            >
              <template v-if="item.sold">
                Sold
              </template>
              <template v-else>
                <b-button
                  v-b-modal.sell-article-modal
                  size="sm"
                  :disabled="item.inStock === 0"
                  style="background: #FF274F;"
                  @click="articleNAME(item.articleNumber); orderItemID(item.orderItemId);sellArticleTeamName()"
                >
                  {{ item.inStock === 0 ? 'No Stock' : 'Sell' }}
                </b-button>
              </template>
            </td> -->
            <td style="padding: 9px; text-align: left; cursor: pointer">
              <b-button
                v-if=" item.orderItemState == 0"
                v-b-modal.add-team-modal
                style="width: 100%; background: #FF274F;"
                size="sm"
                @click="getOrderItem(item.orderItemId)"
              >
                {{ $t('AssignTeam') }}
              </b-button>
              <b-button
                v-else-if="item.isStockAvailable == true"
                size="sm"
                :style="item.orderItemState == 10? ' width: 100%;background-color: rgb(209, 53, 53); border-color: #b6ffb6; color: #222222' : 'background-color: #b6ffb6; border-color: #b6ffb6; color: #222222'"
              >
                {{ getOrderItemStateName(item.orderItemState) }}
              </b-button>
              <b-button
                v-else
                size="sm"
                style="background-color: rgb(209, 53, 53); opacity: 0.7; color: white; border: none"
              >
                {{ $t('NotinStock') }}
              </b-button>
            </td>
            <td
              v-b-toggle.edit-order-item
              style="padding: 9px; text-align: center;"
              @click="getEditOrderItemm(item.orderItemId); getEditOrderItemMaterialVariant(item.orderItemId); articleForTitle(item.articleNumber)"
            >
              <!-- @click="editOrder(item.orderNumber)" -->
              <!--  -->
              <b-icon-pencil />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="getCreatedOrdersByOrderNumber.length > 15">
      <b-pagination
        v-model="page"
        :total-rows="getTotalItemsForOrderItems"
        :per-page="pageSize"
        first-number
        last-number
        align="fill"
        size="md"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
        </template>
        <template #next-text>
          <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
        </template>
      </b-pagination>
    </div>
    <AddTeamModal @assign="addTeamToArticle" />
    <SellArticleModal
      :order-number="orderItemId"
      :article-name="articleee"
      @sellDresss="sellProductInStock"
    />
    <div
      v-if="isSidebarOpen"
      class="backdrop"
    />
    <EditOrderItemModal
      :article-number="articleNumber"
      @editOrderItemm="edittOrder"
    />
    <EditOrderItemToggle
      :article-number="articleNumber"
      @onCancelToggle="onCancelToggle"
      @editOrderItemm="edittOrder"
    />
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import AddTeamModal from '@/components/orders/modals/AddTeamModal.vue';
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters } from 'vuex';
import EditOrderItemToggle from '@/components/orders/modals/EditOrderItemToggle.vue'
// import Spinner from '../Spinner/Spinner.vue';
import SellArticleModal from './modals/SellArticleOrderModal.vue'
import EditOrderItemModal from './modals/EditOrderItemModal.vue'
import { client } from '../../domainConfig'


export default {
  components: {
    AddTeamModal,
    SellArticleModal,
    EditOrderItemModal,
    EditOrderItemToggle,
    // Spinner,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['orderId', 'createOrder'],
  data() {
    return {
      isSidebarOpen: false,
      initialId: '',
      materialPropId: '',
      client: client.clientName,
      status: true,
      classNameCh: '',
      articleee: null,
      searchTerm: '',
      rows: [],
      articleNumber: null,
      orderItemId: null,
      materialObject: {},
      editMat: {},
      selectedOrderItem: '',
      ord: '',
      page: 1,
      pageSize: 15,
      stages: [
        {
          value: 0,
          name: 'Created',
        },
        {
          value: 1,
          name: 'Assigned',
        },
        {
          value: 2,
          name: 'Confirmed',
        },
        {
          value: 3,
          name: 'InProduction',
        },
        {
          value: 4,
          name: 'InSewing',
        },
        {
          value: 5,
          name: 'InHandSewing',
        },
        {
          value: 6,
          name: 'QualityPassed',
        },
        {
          value: 7,
          name: 'ReadyToDeliver',
        },
        {
          value: 8,
          name: 'Delivered',
        },
        {
          value: 9,
          name: 'StockNotAvailable',
        },
        {
          value: 10,
          name: 'QualityCheckNotPassed',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['getCreatedOrdersByOrderNumber', 'getCurrentPageForCreatedOrders', 'getTotalItemsForOrderItems', 'getCurrentPageForOrderItems', 'getIsLoading']),
    rowsEr() {
      return this.rows.filter((post) => {
        return post.orderNumber.toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    },
    getOrderItemStateName() {
      return (stateValue) => {
        const stage = this.stages.find((stageee) => { return stageee.value === stateValue });
        return stage ? stage.name : '';
      };
    },
  },
  watch: {
    initialId() {
      this.immediateId(this.initialId);
    },
    page(value) {
      // this.changeLoadingtoTrue(false)

      this.loadCreatedOrdersByOrderNumber({
        id: this.orderId,
        pageNumber: value,
        pageSize: this.pageSize,
        successCallback: () => {
          this.changeLoadingtoTrue(true)
        },
      });
    },
  },
  methods: {
    ...mapActions(['edit_OrderItem', 'teamNames', 'loadCreatedOrdersByOrderNumber', 'assignOrderItemToTeamLeader', 'loadCreatedOreders', 'changeLoadingtoTrue', 'resetCreatedOrdersByOrderNumber', 'getTeamNamesPagination', 'sell_productInStock', 'get_edit_OrderItem', 'edit_OrderItem_MaterialVariants']),
    // async fetch() {
    //   try {
    //             // this.changeLoadingtoTrue(false)

    //     await this.loadCreatedOrdersByOrderNumber({
    //       id: this.orderId,
    //       pageNumber: this.pageNumber,
    //       pageSize: this.pageSize,
    //     })
    //       .then((res) => {
    //         console.log(res);
    //         this.changeLoadingtoTrue(true)
    //       })
    //   } catch (e) {
    //     console.log(e);
    //             // this.changeLoadingtoTrue(false)

    //   }
    // },
    edittOrder(value) {
      this.edit_OrderItem({
        object: value,
        successCallback: () => {
          this.loadCreatedOrdersByOrderNumber({
            id: this.orderId,
            pageNumber: this.page,
            pageSize: this.pageSize,
            successCallback: () => {
              this.changeLoadingtoTrue(true)
            },
          });
        },
      })
    },
    getOrderItem(orderItem) {
      this.teamNames({
        pageNumber: 1,

        pageSize: 15,

      });
      console.log(orderItem)
      this.selectedOrderItem = orderItem
    },
    articleForTitle(value) {
      this.articleNumber = value
    },
    async addTeamToArticle(obj) {
      const objToSend = {
        orderNumber: this.orderId,
        orderItemId: this.selectedOrderItem,
        teamNameId: obj.value,
      };
      await this.assignOrderItemToTeamLeader({
        object: objToSend,
        successCallback: () => {
          this.loadCreatedOrdersByOrderNumber({
            pageNumber: this.page,
            pageSize: 15,
            id: this.orderId,
          })
          this.resetCreatedOrdersByOrderNumber()
          this.loadCreatedOreders({
            pageNumber: this.getCurrentPageForCreatedOrders,
            pageSize: 15,
            orderNumber: null,
          })
        },
      });
    },
    sellArticleTeamName() {
      this.getTeamNamesPagination({
        pageNumber: 1,
        pageSize: 15,
      })
    },
    onCancelToggle() {
      this.isSidebarOpen = false
    },
    getEditOrderItemm(value) {
      this.isSidebarOpen = true
      this.get_edit_OrderItem(value)
    },
    getEditOrderItemMaterialVariant(value) {
      this.edit_OrderItem_MaterialVariants(value)
    },
    orderItemID(value) {
      this.orderItemId = value
    },

    sellProductInStock(value) {
      console.log('dbb', value)
      this.sell_productInStock({
        orderItemId: value.orderItemId,
        articleNumber: value.articleNumber,
        assignmentTeamNameId: value.assignmentTeamNameId,
        successCallback: () => {
          this.resetCreatedOrdersByOrderNumber()
          this.loadCreatedOrdersByOrderNumber({
            id: this.orderId,
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
          this.loadCreatedOreders({
            pageNumber: this.getCurrentPageForCreatedOrders,
            pageSize: 15,
            orderNumber: this.ord,
          })
        },
      })
    },

    editMaterial(item) {
      this.editMat = item;
      this.$bvModal.show('modal-edit-dress');
    },

    articleNAME(value) {
      this.articleee = value
    },

    materialsData(obj) {
      this.materialObject = obj;
      this.addTeamToArticle();
    },
    // materialsData

    immediateId(e) {
      this.$emit('click', e);
    },
    matProps(article) {
      this.materialPropId = article.materialId;
      this.classNameCh = article.colorName;
    },
  },
};
</script>

<style scoped lang="scss">
.color-listing__wrapper {
  margin-left: 20px;
  .mats-listing {
    margin-top: 0;
  }
  .table__search-input {
    visibility: hidden;
  }
  input {
    width: 100px;
  }
}
.button {
  margin-bottom: 15px !important;
}

.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

tr:last-child {
  border-bottom: none !important;
}
</style>
