<template>
  <div>
    <b-modal
      id="add-team-modal"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('AssignTeam')"
      hide-footer
      class="custom-modal"
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-select-1"
          :label="$t('TeamN')"
          label-for="select-1"
        >
          <vue-select
            v-model="$v.form.teamName.$model"
            :options="formattedTeams"
            :placeholder="$t('SelectTeam')"
            label="label"
            :state="validateState('teamName')"
            aria-describedby="input-1-live-feedback"
            required
          >
            <template
              slot="option"
              slot-scope="option"
            >
              <span>
                {{ option.teamName }}
                <span style="float: right; font-size: 14px; font-weight: bold;">
                  {{ $t('ActiveOrders') }} {{ option.countItems }}
                </span>
              </span>
            </template>
          </vue-select>
          <b style="color: black;">{{ getTeamsNames.countItems }}</b>
        </b-form-group>
        <b-form-group />
        <hr style="color: lightgray">
        <div class="buttonsEverywhere">
          <b-button
            type="button"
            variant="primary"
            class="buttonSubmit"
            style="margin-top: 10px;"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; margin-top: 10px; background: white; color: black; border-color: white;font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate';
import {
  required,
}
from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],

  data() {
    return {
      form: {
        teamName: '',
      },
      rows: [],
    }
  },
  validations: {
    form: {
      teamName: {
        required,
      },
    },

  },
  computed: {
    ...mapGetters(['getTeamsNames']),
    formattedTeams() {
      return this.getTeamsNames.map((team) => {
        const countItems = team.countItems === null ? 0 : team.countItems;
        const label = `${team.teamName} - Active Orders ${countItems} `;
        return {
          label,
          value: team.teamNameId,
          teamName: team.teamName,
          countItems,
        };
      });
    },
  },
  mounted() {
    this.teamNames({
      pageNumber: 1,

      pageSize: 15,

    });
  },
  methods: {
    ...mapActions(['teamNames']),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$emit('assign', this.form.teamName)
      this.$refs.modal.hide()
      setTimeout(() => {
        this.resetForm()
      }, 10)
    },
    onCancel() {
      this.$refs.modal.hide()
      this.resetForm()
    },
    resetForm() {
      this.form.teamName = ''
      this.form.description = ''
      this.form.measurementType = ''
    },
  },
}
</script>

<style lang="scss" scoped>

  // form {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //   gap: 10px;
  // }
  // .flex-form {
  //   display: flex;
  //   flex-direction: column;
  // }
</style>
