<template>
  <div>
    <b-modal
      id="adjust-delivery-date"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      size="md"
      :title="$t('AdjustedDelivery')"
      hide-footer
    >
      <b-form>
        <div style="display: flex; flex-direction: column; width: 100%; justify-content: space-between;">
          <b-form-group
            id="input-group-1"
            :label="$t('SelectRevisedDate')"
            label-for="input-1"
            style="width: 100%;"
          >
            <date-picker
              v-model="form.adjustedDeliveryDate"
              type="date"
              value-type="format"
              :placeholder="$t('SelectD')"
              :format="'YYYY-MM-DD'"
              style="width: 100%;"
            />
          </b-form-group>
          <b-form-group
            :label="$t('Notes')"
          >

            <b-form-textarea
              id="input-15"
              v-model="form.notes"
            />

          </b-form-group>
        </div>
      </b-form>
      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('VacationSubmit') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
  },
  props: ['orderNumber'],
  data() {
    return {
      form: {
        orderNumber: null,
        adjustedDeliveryDate: null,
        notes: null,
      },
    }
  },
  watch: {
    orderNumber(value) {
      this.form.orderNumber = value
    },
  },
  methods: {
    onSubmit() {
      // this.$v.form.$touch();
      // if (this.$v.form.$anyError) {
      //   return;
      // }
      this.$emit('adjustDeliveryDatee', this.form)
      this.$refs.modal.hide()
      this.resetForm()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    resetForm() {
      this.form.adjustedDeliveryDate = null
      this.form.notes = null
    },
  },
}
</script>
