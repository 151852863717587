<template>
  <div
    class="mats-listing__wrapper color-listing__wrapper"
    style="margin-top: -8px;"
  >
    <!-- <button
      v-b-modal.modal-add-color
      class="button"
      :class="{'button--hidden': createOrder}"
    >
      <b-icon-plus />
      {{ $t('AddColor') }}
    </button> -->
    <div class="table__search-input">
      <input
        v-model="searchTerm"
        type="text"
        style="width: 255px"
      >
    </div>
    <!-- <hr class="mt-2 mb-3"> -->

    <div style="width: 100%; padding-top: 0px; margin-top: 15px">
      <table
        class="team_table"
        :class="{'button--disabled': getCreatedOrdersByOrderNumber.length == 0}"
      >
        <thead>
          <tr>
            <th>
              {{ $t('ArticleName') }}
            </th>
            <th>
              {{ $t("Stock") }}
            </th>
            <th>
              {{ $t("Sell") }}
            </th>
            <th
              v-if="getLoggedInUser.role !== 'Designer' && getLoggedInUser.role !== 'SalesPerson'"
            >
              {{ $t('Assign') }}
            </th>
            <th>
              {{ $t("Actions") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in getCreatedOrdersByOrderNumber"
            :key="index"
            :style="classNameCh === item.orderNumber ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
            @click="matProps(item)"
          >
            <td>
              {{ item.articleNumber }}
            </td>
            <td style="text-align: center;">
              {{ item.inStock }}
            </td>
            <td>
              <template v-if="item.sold">
                {{ $t('Sold') }}
              </template>
              <template v-else>
                <button
                  id="sell"
                  v-b-modal.sell-article-modal
                  class="editButton"
                  :disabled="item.inStock === 0"
                  @click="articleNAME(item.articleNumber); orderItemID(item.orderItemId);sellArticleTeamName()"
                >
                  {{ item.inStock === 0 ? `${$t('NoStock')}` : `${$t('Sell')}` }}
                </button>
              </template>
            </td>
            <td
              v-if="getLoggedInUser.role !== 'Designer' && getLoggedInUser.role !== 'SalesPerson'"
            >
              <button
                v-if=" item.orderItemState == 0"
                id="assign"
                v-b-modal.add-team-modal
                class="editButton"
                style="width: 120px !important;"
                @click="getOrderItem(item.orderItemId)"
              >
                {{ $t('AssignTeam') }}
              </button>
              <b-button
                v-else-if="item.isStockAvailable == true"
                size="sm"
                :style="item.orderItemState == 17? ' width: 100%;background-color: rgb(209, 53, 53); border-color: rgb(209, 53, 53); color: white;' : 'background-color: #b6ffb6; border-color: #b6ffb6; color: white; opacity: 0.7;'"
              >
                {{ $t(getOrderItemStateName(item.orderItemState)) }}
              </b-button>
              <b-button
                v-else
                size="sm"
                style="background-color: rgb(209, 53, 53); opacity: 0.7; color: white; border: none"
              >
                {{ $t('NotinStock') }}
              </b-button>
            </td>
            <td
              v-b-toggle.edit-order-item
              @click="getEditOrderItemm(item.orderItemId); getEditOrderItemMaterialVariant(item.orderItemId); articleForTitle(item.articleNumber)"
            >
              <button
                class="editButton"
              >
                {{ $t('Edit') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="getCreatedOrdersByOrderNumber.length > 0">
      <b-pagination
        v-if="getTotalItemsForOrderItems > 15"
        v-model="page"
        :total-rows="getTotalItemsForOrderItems"
        :per-page="pageSize"
        first-number
        last-number
        align="fill"
        size="md"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
        </template>
        <template #next-text>
          <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
        </template>
      </b-pagination>
    </div>
    <div
      v-if="isSidebarOpen"
      class="backdrop"
    />
    <AddTeamModal @assign="addTeamToArticle" />
    <SellArticleModal
      :order-number="orderItemId"
      :article-name="articleee"
      @sellDresss="sellProductInStock"
    />
    <EditOrderItemModal
      :article-number="articleNumber"
      @editOrderItemm="edittOrder"
    />
    <EditOrderItemToggle
      :article-number="articleNumber"
      @onCancelToggle="onCancelToggle"
      @editOrderItemm="edittOrder"
    />
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import AddTeamModal from '@/components/orders/modals/AddTeamModal.vue';
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters } from 'vuex';
// import Spinner from '../Spinner/Spinner.vue';
import EditOrderItemToggle from '@/components/orders/modals/EditOrderItemToggle.vue'
import SellArticleModal from './modals/SellArticleOrderModal.vue'
import EditOrderItemModal from './modals/EditOrderItemModal.vue'
import { client } from '../../domainConfig'


export default {
  components: {
    AddTeamModal,
    SellArticleModal,
    EditOrderItemModal,
    EditOrderItemToggle,
    // Spinner,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['orderId', 'createOrder'],
  data() {
    return {
      isSidebarOpen: false,
      initialId: '',
      materialPropId: '',
      client: client.clientName,
      status: true,
      classNameCh: '',
      articleee: null,
      searchTerm: '',
      rows: [],
      articleNumber: null,
      orderItemId: null,
      materialObject: {},
      editMat: {},
      selectedOrderItem: '',
      ord: '',
      page: 1,
      pageSize: 15,
      stages: [
        {
          name: this.$t('Created'),
          value: 0,
        },
        {
          name: this.$t('Assigned'),
          value: 1,
        },
        {
          name: this.$t('Confirmed'),
          value: 2,
        },
        {
          name: this.$t('InPattern'),
          value: 3,
        },
        {
          name: this.$t('InPatternQualityPassed'),
          value: 4,
        },
        {
          name: this.$t('InPatternQualityNotPassed'),
          value: 5,
        },
        {
          name: this.$t('InPatternCompleted'),
          value: 6,
        },
        {
          name: this.$t('InSewing'),
          value: 7,
        },
        {
          name: this.$t('InSewingQualityPassed'),
          value: 8,
        },
        {
          name: this.$t('InSewingQualityNotPassed'),
          value: 9,
        },
        {
          name: this.$t('InHandSewing'),
          value: 10,
        },
        {
          name: this.$t('InHandSewingQualityPassed'),
          value: 11,
        },
        {
          name: this.$t('InHandSewingQualityNotPassed'),
          value: 12,
        },
        {
          name: this.$t('InControlCheck'),
          value: 13,
        },
        {
          name: this.$t('QualityPassed'),
          value: 14,
        },

        {
          name: this.$t('ReadyToDeliver'),
          value: 15,
        },
        {
          name: this.$t('Delivered'),
          value: 16,
        },
        {
          name: this.$t('StockNotAvailable'),
          value: 17,
        },
        {
          name: this.$t('QualityCheckNotPassed'),
          value: 18,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['getCreatedOrdersByOrderNumber', 'getCurrentPageForCreatedOrders', 'getTotalItemsForOrderItems', 'getCurrentPageForOrderItems', 'getIsLoading', 'getLoggedInUser']),
    rowsEr() {
      return this.rows.filter((post) => {
        return post.orderNumber.toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    },
    getOrderItemStateName() {
      return (stateValue) => {
        const stage = this.stages.find((stageee) => { return stageee.value === stateValue });
        return stage ? stage.name : '';
      };
    },
  },
  watch: {
    initialId() {
      this.immediateId(this.initialId);
    },
    page(value) {
      // this.changeLoadingtoTrue(false)

      this.loadCreatedOrdersByOrderNumber({
        id: this.orderId,
        pageNumber: value,
        pageSize: this.pageSize,
        successCallback: () => {
          // this.changeLoadingtoTrue(true)
        },
      });
    },
  },
  methods: {
    ...mapActions(['edit_OrderItem', 'getMaterialVariantsToMDCPagination', 'teamNames', 'loadCreatedOrdersByOrderNumber', 'assignOrderItemToTeamLeader', 'loadCreatedOreders', 'changeLoadingtoTrue', 'resetCreatedOrdersByOrderNumber', 'getTeamNamesPagination', 'sell_productInStock', 'get_edit_OrderItem', 'edit_OrderItem_MaterialVariants']),
    // async fetch() {
    //   try {
    //             // this.changeLoadingtoTrue(false)

    //     await this.loadCreatedOrdersByOrderNumber({
    //       id: this.orderId,
    //       pageNumber: this.pageNumber,
    //       pageSize: this.pageSize,
    //     })
    //       .then((res) => {
    //         console.log(res);
    //         // this.changeLoadingtoTrue(true)
    //       })
    //   } catch (e) {
    //     console.log(e);
    //             // this.changeLoadingtoTrue(false)

    //   }
    // },
    edittOrder(value) {
      this.edit_OrderItem({
        object: value,
        successCallback: () => {
          this.loadCreatedOrdersByOrderNumber({
            id: this.orderId,
            pageNumber: this.page,
            pageSize: this.pageSize,
            successCallback: () => {
              // this.changeLoadingtoTrue(true)
            },
          });
        },
      })
    },
    getOrderItem(orderItem) {
      this.teamNames({
        pageNumber: 1,

        pageSize: 15,

      });
      console.log(orderItem)
      this.selectedOrderItem = orderItem
    },
    articleForTitle(value) {
      this.articleNumber = value
    },
    async addTeamToArticle(obj) {
      const objToSend = {
        orderNumber: this.orderId,
        orderItemId: this.selectedOrderItem,
        teamNameId: obj.value,
      };
      await this.assignOrderItemToTeamLeader({
        object: objToSend,
        successCallback: () => {
          // this.loadCreatedOrdersByOrderNumber({
          //   pageNumber: this.page,
          //   pageSize: this.pageSize,
          //   id: this.orderId,
          // })
          this.resetCreatedOrdersByOrderNumber()
          this.loadCreatedOreders({
            pageNumber: this.getCurrentPageForCreatedOrders,
            pageSize: this.pageSize,
            orderNumber: null,
          })
        },
      });
    },
    sellArticleTeamName() {
      this.getTeamNamesPagination({
        pageNumber: 1,
        pageSize: this.pageSize,
      })
    },
    onCancelToggle() {
      this.isSidebarOpen = false
    },
    getEditOrderItemm(value) {
      this.isSidebarOpen = true
      this.get_edit_OrderItem(value)
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      })
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Package',
      })
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Set',
      })
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Pair',
      })
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Verse',
      })
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      })
    },
    getEditOrderItemMaterialVariant(value) {
      this.edit_OrderItem_MaterialVariants(value)
    },
    orderItemID(value) {
      this.orderItemId = value
    },

    sellProductInStock(value) {
      console.log('dbb', value)
      this.sell_productInStock({
        orderItemId: value.orderItemId,
        articleNumber: value.articleNumber,
        assignmentTeamNameId: value.assignmentTeamNameId,
        successCallback: () => {
          this.loadCreatedOrdersByOrderNumber({
            id: this.orderId,
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
          this.loadCreatedOreders({
            pageNumber: this.getCurrentPageForCreatedOrders,
            pageSize: this.pageSize,
            orderNumber: null,
          })
        },
      })
    },

    editMaterial(item) {
      this.editMat = item;
      this.$bvModal.show('modal-edit-dress');
    },

    articleNAME(value) {
      this.articleee = value
    },

    materialsData(obj) {
      this.materialObject = obj;
      this.addTeamToArticle();
    },
    // materialsData

    immediateId(e) {
      this.$emit('click', e);
    },
    matProps(article) {
      this.materialPropId = article.materialId;
      this.classNameCh = article.colorName;
    },
  },
};
</script>

<style scoped lang="scss">

.team_table td {
  padding: 11px 9px !important;
}

.team_table th {
  // text-align: center;
}

// .editButton {
//   width: fit-content !important;
//   height: 30px;
// }
.color-listing__wrapper {
  margin-left: 20px;
  .mats-listing {
    margin-top: 0;
  }
  .table__search-input {
    visibility: hidden;
  }
  input {
    width: 100px;
  }
}
.button {
  margin-bottom: 15px !important;
}

.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

tr:last-child {
  border-bottom: none !important;
}

.editButton {
  width: 104px !important;
}

@media screen and (max-width: 1100px) {
  .color-listing__wrapper {
    margin-left: 0px;
  }
}
</style>
